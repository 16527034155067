// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"2e1127242bae0142f2b5644de8f60fe184ae9f72"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import { init, replayIntegration, setUser } from "@sentry/nextjs"
import { getUserId } from "src/tracking/state"
import { sentryOptions } from "./sentry.base.config"

init({
  ...sentryOptions,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  // replaysSessionSampleRate: 0.1,
  replaysSessionSampleRate: 0,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    replayIntegration({
      // Additional Replay configuration goes in here, for example:
      maskAllInputs: true,
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
})

setUser({ id: getUserId() })
