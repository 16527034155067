import { nanoid } from "nanoid"
import { localStoragePersist } from "src/utilities/localStoragePersist"
import { create as createStore } from "zustand"

type State = {
  id: string
}

const defaultState: State = {
  id: nanoid(),
}

const store = createStore(
  localStoragePersist(() => defaultState, {
    name: "id",
    persistInitialState: true,
  }),
)

export function getUserId() {
  return store.getState().id
}
