import { isBrowser } from "src/utilities/env"
import { PersistOptions, StateStorage, createJSONStorage, persist } from "zustand/middleware"

const prefix = "fam-"

/**
 * An SSR-safe zustand storage implementation.
 */
export class SsrStorage implements StateStorage {
  constructor(private readonly storage: () => Storage) {}

  getItem(name: string) {
    if (isBrowser()) {
      return this.storage().getItem(name)
    }
    return null
  }

  setItem(name: string, json: string) {
    if (isBrowser()) {
      this.storage().setItem(name, json)
    }
  }

  removeItem(name: string) {
    if (isBrowser()) {
      this.storage().removeItem(name)
    }
  }
}

/**
 * A wrapper around the default zustand persist plugin.
 *
 * - SSR-safe (does not throw on accessing localStorage on the server)
 * - Prefixes all keys to avoid collisions
 * - By default, resets state to the defaults on a version change
 *
 * @see https://docs.pmnd.rs/zustand/integrations/persisting-store-data
 */
export const localStoragePersist = <State>(
  createDefaultState: () => State,
  options: PersistOptions<State, Partial<State>> & {
    persistInitialState?: boolean
  },
) => {
  if (!options.name) {
    throw new Error("Please provide a name")
  }

  const name = `${prefix}${options.name}`
  const storage = options.storage || createJSONStorage(() => new SsrStorage(() => window.localStorage))!

  // Since v5, zustand no longer saves the initial state to local storage. We
  // re-implement the v4 behavior because we want random ids to be persistent on
  // subsequent page loads.
  if (isBrowser() && options.persistInitialState && !storage.getItem(name)) {
    storage.setItem(name, { state: createDefaultState() })
  }

  return persist(createDefaultState, {
    ...options,
    name: name,
    storage: storage,

    // We provide a default migration that resets the state to the current
    // defaults. This avoids runtime errors when a user has an old version of
    // the state in their local storage that no longer matches new code.
    migrate: (persistedState, version) => {
      const oldVersion = version || 0
      const newVersion = options.version || 0

      console.debug(
        `📦 Local storage state "${options.name}" was reset because it changed from v${oldVersion} to v${newVersion}.`,
      )

      return createDefaultState()
    },
  })
}
